import { css } from "styled-components"

export default css`
  /* Write your custom global CSS here */
  body {
    font-family: sans-serif;
    color: #222831;
  }
  button {
    cursor: pointer;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .grid-sizer {
    width: calc(100% / 3);
  }
`
